.content {
  width: 744px;
  padding-bottom: 48px;

  .desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #1e1e20;
    margin-bottom: 8px;
  }
}

.textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.inputTitle {
  all: unset;
  width: 100%;
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;
  color: #1e1e20;
  margin-bottom: 24px;

  &::placeholder {
    color: #c1c4cb;
  }
}
