@import '../../../../shared/theme/colors.scss';

.name {
  font-weight: bold;
}

.card {
  margin-top: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
  width: 352px;
  min-width: 343px;
  min-height: 148px;
  box-shadow: 4px 4px 12px rgba(101, 104, 118, 0.2);
  border-radius: 8px;
  padding: 20px 16px 16px;
  word-break: break-all;

  &-small {
    min-height: 80px;
  }

  &-mobile {
    width: 100%;
  }
}

.description {
  color: $clr-grey5;
}

.date {
  margin-top: auto;
}

.status {
  position: absolute;
  top: -14px;
  right: 16px;
  border-radius: 40px;
  background-color: $clr-brunoyam;
  padding: 6px 12px;
  color: $clr-white;
}

.icons-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  cursor: pointer;
  background-color: $clr-white;
}
