.lesson {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 16px;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1e1e20;
  }

  .point {
    display: flex;
    align-items: center;

    .circle {
      width: 6px;
      height: 6px;
      background: #2c77e7;
      border-radius: 50%;
      margin-right: 10px;
    }

    .name {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #2c77e7;
    }
  }
}
