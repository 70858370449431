.header {
  width: 744px;
  background: #f0f2f5;
  border-bottom: 1px solid #d9d9d9;
  height: 56px;
  border-radius: 8px 8px 0 0;
  position: relative;
  padding-left: 32px;
  display: flex;

  .item {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #59647a;
    padding: 16px 0 38px 0; // 16px 0;
    width: fit-content;
    cursor: pointer;

    &_active {
      border-bottom: 2px solid #59647a;
    }

    &:first-child {
      margin-right: 16px;
    }
  }

  .delete {
    width: 36px;
    height: 36px;
    background-color: #59647a;
    border-radius: 0 8px 0 0;
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 0;

    .img {
      width: 24px;
      height: 24px;
      margin: auto auto;
      padding: 0;
    }
  }
}

.main {
  width: 744px;
  height: 234px;
  background-color: #f0f2f5;
  border-radius: 0 0 8px 8px;
  padding: 32px 0 0 32px;
  margin-bottom: 32px;

  .head {
    color: #9aa1b0;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
  }

  .format {
    color: #9aa1b0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 8px;
  }

  .dropZone {
    width: 100%;
    height: 120px;
    margin: 0;

    .button {
      width: 256px;
      height: 56px;
      padding: 16px 24px;
      margin-top: 32px;
      background-color: #eb5d18;
      border: none;
      border-radius: 4px;
      color: #ffffff;
      cursor: pointer;
      line-height: 24px;
      font-size: 18px;
    }

    .hidden {
      opacity: 0;
      width: 0;
      height: 0;
      line-height: 0;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }
  }
}

.block {
  width: 744px;
  background-color: #f0f2f5;
  border-radius: 0 0 8px 8px;
  padding: 32px;
  margin-bottom: 32px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #9aa1b0;
    margin-bottom: 24px;
  }

  .blockInput {
    display: flex;

    .input {
      width: 466px;
      height: 56px;
      background: #ffffff;
      border-radius: 1px;
      margin-right: 16px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      padding: 16px;
      border: none;

      &::placeholder {
        color: #59647a;
      }
    }

    .button {
      width: 198px;
      height: 56px;
      background: #eb5d18;
      border: 1px solid #eb5d18;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;

      &:disabled {
        cursor: default;
        background: #d9d9d9;
        color: #59647a;
        border-color: #d9d9d9;
      }
    }
  }
}
