.modal {
  padding: 16px 16px 40px 40px;
  width: 500px;
  height: 446px;
  background: #ffffff;
  cursor: default;

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 16px;
  }

  .back {
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    margin: 0 -16px 32px -16px;
    cursor: pointer;

    .backTitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #1e1e20;
      background-color: white;
      width: 100%;
      text-align: center;
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;

    & > svg {
      cursor: pointer;
    }
  }

  .title {
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    color: #1e1e20;
    margin-bottom: 32px;
  }

  .input {
    margin-bottom: 32px;
    padding-right: 20px;
    width: 100%;
  }

  .description {
    margin-bottom: 32px;
    padding-right: 20px;
    width: 100%;
    height: 120px;
  }

  .buttonSave {
    width: 147px;
    height: 56px;
    background: #eb5d18;
    border: 1px solid #eb5d18;
    border-radius: 4px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;

    &:disabled {
      background: #d9d9d9;
      color: #59647a;
      border-color: #d9d9d9;
      cursor: default;
    }
  }
}
