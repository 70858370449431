@import '../../../../shared/theme/colors.scss';

.wrapper {
  width: 760px;
  min-height: 400px;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: auto;
}

.modal {
  &-mobile {
    width: 95%;
  }
}

.file {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
}

.buttons {
  display: flex;
  margin-top: auto;
  gap: 8px;
  padding-top: 8px;
}
