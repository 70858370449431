.popup {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  overflow: auto;

  &-opened {
    display: flex;
  }
}
