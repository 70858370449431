.root {
  width: 446px;
  background: rgba(17, 194, 98, 0.1);
  border-radius: 1px;
  padding: 16px 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  color: #11c262;

  margin-top: 60px;

  @media (max-width: 500px) {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
