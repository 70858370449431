@import '../../../../../shared/theme/colors.scss';

.task-status {
  position: absolute;
  width: 100%;
  height: 48px;
  background-color: $clr-grey2;
  border-radius: 0 0 8px 8px;
  bottom: 0;
  left: 0;
  padding-left: 64px;
  display: flex;
  align-items: center;

  @media (max-width: 599px) {
    border-radius: 0;
    padding-left: 16px;
  }
}

.success {
  color: $clr-white;
  background-color: $clr-success;
}

.error {
  color: $clr-white;
  background-color: $clr-error;
}
