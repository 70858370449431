@import '../../../shared/theme/colors.scss';

.container {
  padding-top: 40px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.answer {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.tabs {
  margin-top: 24px;
}
/* 
.file {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
} */
