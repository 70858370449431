@import '../../../../theme/colors.scss';

$wrapper-height: 90vh;
$interlocutor-height: 70px;
$dialog-margin: 32px;
$wrapper-padding: 40px;

$side-chat-padding: 94px;
$footer-gap: 16px;
$icon-size: 24px;

.wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: $wrapper-height;
  border-radius: 8px;
  border: 1px solid black;
  background-color: $clr-white;
  padding: $wrapper-padding;
}

.dialog {
  border: 1px solid $clr-grey3;
  border-radius: 8px;
  height: calc(
    $wrapper-height - $interlocutor-height - $dialog-margin - 2 *
      $wrapper-padding
  );
  margin-top: $dialog-margin;
  display: flex;
  flex-direction: column;
}

.footer {
  border-radius: 0 0 8px 8px;
  background-color: $clr-grey2;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $footer-gap;
  margin-top: auto;
  padding: 0 calc($side-chat-padding - $footer-gap - $icon-size) 0
    $side-chat-padding;
}

.input {
  width: 100%;
  background-color: $clr-white;
}

.interlocutor {
  height: $interlocutor-height;

  &-course {
    margin-top: 4px;
    color: $clr-grey5;
  }
}

.messages-history {
  height: 100%;
  padding: 0 $side-chat-padding 24px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.chat-message {
  margin-top: 8px;
}

.button-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  // TODO: Привести исходники иконок к одинаковым размерам
  transform: scale(1.2);

  & > path {
    fill: $clr-grey4;
  }
}

.button-send {
  cursor: pointer;

  & > g > path {
    fill: $clr-brunoyam;
  }
}

.date-tag {
  color: $clr-grey5;
  text-align: center;
  display: block;
  margin: 12px 0;
}
