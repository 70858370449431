.link {
  color: #ffcbb1;
  cursor: 'pointer';

  & span {
    color: #ffcbb1;
  }
}

.linkBlock {
  position: absolute;
  left: -14px;
  top: 39px;
  width: 400px;
  height: 88px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 4px 4px 12px rgba(101, 104, 118, 0.2);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .link {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-overflow: ellipsis;
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden;
    color: #1e1e20;
  }

  .button {
    padding: 16px 24px;
    background: #eb5d18;
    border: 1px solid #eb5d18;
    border-radius: 4px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-left: 16px;
  }
}
