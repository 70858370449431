.buttonBlock {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding: 0 48px;

  & > button {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
}
