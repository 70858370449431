@import '../../../../../theme/colors.scss';

$br: 12px;

.wrapper {
  padding: 16px;
  max-width: 540px;
  min-width: 60px;
  position: relative;
  width: fit-content;
}

.owned-by-sender {
  background-color: $clr-grey4;
  margin-left: auto;
  border-radius: $br $br 0 $br;
  color: $clr-white;
}

.owned-by-recipient {
  background-color: $clr-grey2;
  margin-right: auto;
  border-radius: $br $br $br 0;
  color: $clr-black;
}

.time {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.recipient-time {
  color: $clr-grey4;
}

.text {
  display: block;
  padding-bottom: 8px;
  word-break: break-all;
}
