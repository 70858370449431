@import '../../../theme/colors.scss';

.box {
  width: 100%;
  max-width: 190px;
  z-index: 500;
  background-color: white;
  padding: 8px;
  box-shadow: 16px 4px 32px rgba(101, 104, 118, 0.3);
  border-radius: 4px;
}
