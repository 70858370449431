.editor {
  margin-bottom: 24px;

  & span {
    font-size: 20px;
    line-height: 28px;
    color: #1e1e20;
  }

  & > div {
    & > div {
      & div {
        display: inline;

        & a {
          & span {
            cursor: 'pointer';
            color: #eb5d18;
          }
        }
      }
    }
  }

  & h1 {
    & span {
      font-weight: 700;
      font-size: 28px;
      line-height: 40px;
      color: #1e1e20;
    }
  }

  & h2 {
    & span {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #1e1e20;
    }
  }

  & h3 {
    & span {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #1e1e20;
    }
  }

  & a {
    & span {
      color: '#FFCBB1';
      cursor: 'pointer';
    }
  }

  .panel {
    position: absolute;
    display: block;
  }
}

.link-popup {
  display: none;
}
