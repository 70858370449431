@import '../../../../shared/theme/colors.scss';

.detailed-task {
  margin-top: 48px;
  margin-bottom: 48px;
  position: relative;
}

.header-callout,
.test-input {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.subheader {
  margin-top: 32px;
}

.answer {
  margin-top: 4px;
}

.with-bottom-margin {
  margin-bottom: 32px;
  border-radius: 0;

  &-mobile {
    margin-bottom: 64px;
  }
}

.button {
  display: flex;
  gap: 10px;
  align-items: center;
}
