.popup {
  &-container {
    display: flex;
    flex-direction: column;
    width: 500px;
    max-height: 686px;
    background-color: #fff;
    padding: 40px;
    position: relative;
  }

  &-title {
    margin-bottom: 32px;
    font-size: 34px;
    line-height: 46px;
    font-weight: 700;
  }

  &-close {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    padding: 10px;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  &-field {
    text-transform: capitalize;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &-item {
      text-transform: capitalize;
    }
  }

  &-button {
    padding: 16px 24px;
    max-width: 147px;
    max-height: 56px;
    border-color: #eb5d18;
    background-color: #eb5d18;
    cursor: pointer;
    pointer-events: all;

    &-disabled {
      border-color: #d9d9d9;
      background-color: #d9d9d9;
      pointer-events: none;
      cursor: auto;
    }
  }
}

.back {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
}
