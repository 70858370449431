@use '../../../shared/theme/mixins.scss' as *;

@mixin padding-top {
  padding-top: 40px;
}

.page-container {
  @include page-container;
  @include padding-top;

  &-tablet {
    @include page-container-tablet;
    @include padding-top;
  }

  &-mobile {
    @include page-container-mobile;
    @include padding-top;
  }
}

.chats {
  display: flex;
  margin-top: 24px;
  gap: 24px;
}
