@use '../../../../shared/theme/colors.scss' as *;

.wrapper {
  width: 352px;
  height: 148px;
  padding: 20px 16px;
  box-shadow: 4px 4px 12px rgba(101, 104, 118, 0.2);
  border-radius: 8px;
  cursor: pointer;
}

.full-name {
  font-weight: bold;
}

.course-name {
  margin-top: 4px;
  color: $clr-grey5;
}

.text {
  margin-top: 12px;
}

.date {
  margin-top: 4px;
  color: $clr-grey5;
}
