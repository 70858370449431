.button {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1e1e20;

  & p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1e1e20;
  }
}

.inputLink {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 16px;
  margin-right: 16px;
  width: 446px;

  &::placeholder {
    color: #59647a;
  }
}

.buttonLink {
  background: #eb5d18;
  border: 1px solid #eb5d18;
  border-radius: 4px;
  width: 147px;
  height: 56px;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  &:disabled {
    background: #d9d9d9;
    border-color: #d9d9d9;
    color: #59647a;
    cursor: default;
  }
}
