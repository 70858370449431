.videoAdd {
  background: #f0f2f5;
  border-radius: 8px;
  padding: 32px;
  position: relative;

  .closeButton {
    background: #59647a;
    border-radius: 0px 8px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #9aa1b0;
    margin-bottom: 8px;
  }

  .discription {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #9aa1b0;
    margin-bottom: 24px;
  }

  .row {
    display: flex;

    .input {
      all: unset;
      background: #ffffff;
      border-radius: 1px;
      padding: 16px;
      color: #1e1e20;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-right: 16px;
      width: 466px;

      &::placeholder {
        color: #59647a;
      }
    }

    .button {
      background: #eb5d18;
      border: 1px solid #eb5d18;
      border-radius: 4px;
      width: 198px;
      height: 56px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;

      &:disabled {
        cursor: default;
        background: #d9d9d9;
        color: #59647a;
        border-color: #d9d9d9;
      }
    }
  }
}

.videoContent {
  position: relative;

  .video {
    border-radius: 8px;
    width: 872px;
    height: 488px;
    margin-left: -64px;
  }

  .button {
    display: none;
    width: 36px;
    height: 36px;
    background: #59647a;
    border-radius: 0px 8px 0px 0px;
    position: absolute;
    top: 0;
    right: -64px;
    padding: 6px 0;
  }

  &:hover {
    .button {
      display: block;
    }
  }
}
