.text {
  margin: 4px 0 40px 0;
  font-size: 18px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 446px;
}

.btn {
  margin-top: 40px;
}

.errorMessage {
  width: 446px;
  padding: 16px;
  font-size: 18px;
  color: #da1b32;
  background: rgba(218, 27, 50, 0.1);
  border-radius: 1px;
  margin-top: 24px;

  @media (max-width: 500px) {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .block {
    width: 100%;
    gap: 24px;
  }

  .btn {
    margin-top: 24px;
  }
}
