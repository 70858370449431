$clr-brunoyam: #eb5d18;
$clr-blue: #2c77e7;
$clr-chartreuse: #cfd923;
$clr-rose: #db5b73;
$clr-violet: #a651b4;
$clr-yellow: #f8c456;

$clr-brunoyam-hover: #ff651a;
$clr-blue-hover: #3f88f5;
$clr-chartreuse-hover: #e4ee39;
$clr-rose-hover: #f56e88;
$clr-violet-hover: #be69cc;
$clr-yellow-hover: #ffd272;

$clr-black: #1e1e20;
$clr-grey5: #59647a;
$clr-grey4: #9aa1b0;
$clr-grey3: #d9d9d9;
$clr-grey2: #f0f2f5;
$clr-grey1: #f9f9fa;
$clr-white: #ffffff;

$clr-success: #11c262;
$clr-error: #da1b32;
